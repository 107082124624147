import React from "react"
import { CarRentalAPI } from "../../../../Api/carrental";

export default function ViewModel() {
    const [carRental, setCarRental] = React.useState([]);

    React.useEffect(()=>{
        CarRentalAPI.fetchCarRental({page:1, limit:6, filter:""}).then((response)=> {
            setCarRental(response);
        })
    },[])

    return {
        carRental
    }
}