import Network from "../helpers/network";

export const TicketAPI = {
    fetchTicketPanigtion: async ({page, limit, filter}) => {
        const response = await Network.get(`/v1/public/ticket?page=${page}&page_size=${limit}${filter}`);
        return response;
    },
    fetchTicket: async ({page, limit, filter}) => {
        const response = await Network.get(`/v1/public/ticket?page=${page}&page_size=${limit}${filter}`);
        return response.data;
    },
    fetchTicketByID: async ({id}) => {
        const response = await Network.get(`/v1/public/ticket/${id}`);
        return response.data;
    },
    fetchCategory: async () => {
        const response = await Network.get(`/v1/public/ticket/category`);
        return response.data;
    },
    creatTransection: async (body) => {
        const response = await Network.post(`/v1/public/ticket`, body);
        return response.data;
    },
}