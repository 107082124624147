import React from "react";
import { Link } from "react-router-dom";
import i18n from "../i18n";

export default function BlogSidebar({name, image}){
    return(
        <>
        <div className="lg:col-span-4 md:col-span-6">
            <div className="sticky top-20">
                <h5 className="text-lg font-medium bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800 rounded-md p-2 text-center">{i18n.t("author")}</h5>
                <div className="text-center mt-8">
                    <img src={image} style={{ objectFit: 'cover', width: '100%', height: '100%' }} className="h-20 w-20 mx-auto rounded-full shadow mb-4" alt=""/>
                    <Link to="" className="text-lg font-medium hover:text-red-500 transition-all duration-500 ease-in-out h5">{name}</Link>
                </div>
            </div>
        </div>
        </>
    )
}