import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";

import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import Switcher from "../../components/switcher";

import {FiActivity, FiCamera, FiCalendar, FiClock, FiDollarSign, FiMapPin} from '../../assets/icons/vander'

import Lightbox from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css';

import { TourAPI } from "../../Api/tour";
import i18n from "../../i18n";
import Swal from 'sweetalert2';

export default function TourDetail(){

    let [isLoading, setIsLoading] = useState(true)
    let [tour, setTour] = useState([])
    let [imageShows, setImageShows] = useState([])
    let [images, setImages] = useState([])

    let [photoIndex, setActiveIndex] = useState(0);
    let [isOpen, setOpen] = useState(false);
    let [form, setForm] = React.useState({});

    const changeValue = (e) => {
        setForm({
          ...form,
          [e.target.name]: e.target.value,
        })
    }

    let handleCLick = (index) => {
        setActiveIndex(index)
        setOpen(true);
    }

    let imageCss = [
        "md:col-span-8 col-span-7",
        "md:col-span-4 col-span-5",
        "md:col-span-4 col-span-5",
        "md:col-span-8 col-span-7",
    ]

    let params = useParams()
    let id = params.id

    React.useEffect(()=>{
        TourAPI.fetchTourByID({id:id}).then((resp) => {

            resp.options = resp.options.map((data) => {
                data.items = data.items.map((items, index) => {
                    items.selected = (index == 0 ? true : false)
                    return items
                })
                return data
            })

            setTour(resp);
            if(resp.images.length > 0){
                let imgs = [];
                let imgShows = [];
                resp.images.forEach(element => {
                    if(element?.url !== ""){
                        imgs.push(element?.url);
                        if(imgShows.length <= 3){
                            imgShows.push(element?.url);
                        }
                    }
                });
                setImages(imgs);
                setImageShows(imgShows);
            }
            setIsLoading(true);
        })
    },[id])

    return(
        !isLoading ? <></>
        : <>
         <Navbar navclass="defaultscroll is-sticky" navlight={true} manuclass="justify-end nav-light"/>
        <section className="relative table w-full items-center py-36 bg-[url('../../assets/images/bg/cta.jpg')] bg-top bg-no-repeat bg-cover">
            <div className="absolute inset-0 bg-gradient-to-b from-slate-900/60 via-slate-900/80 to-slate-900"></div>
            <div className="container relative">
                <div className="grid grid-cols-1 pb-8 text-center mt-10">
                    <h3 className="text-3xl leading-normal tracking-wider font-semibold text-white">{tour?.name ? tour.name : ''}</h3>
                </div>
            </div>
        </section>
        <section className="relative md:py-24 py-16">
            <div className="container relative">
                <div className="grid md:grid-cols-12 grid-cols-1 gap-6">
                    <div className="lg:col-span-12 md:col-span-12">
                        <div className="grid grid-cols-12 gap-4">
                            {
                                imageShows.map((image, index, array) => {
                                    var style = imageCss[index];
                                    if(index === (array.length - 1)){
                                        if(index % 2 === 0){
                                            style = "md:col-span-12 col-span-12";
                                        }
                                    }
                                    return (
                                        <div className={style} key={index}>
                                            <div className="group relative overflow-hidden rounded shadow dark:shadow-gray-800">
                                                <img src={image} className="w-full lg:h-60 md:h-44 h-48 object-cover" alt=""/>
                                                <div className="absolute inset-0 group-hover:bg-slate-900/70 duration-500 ease-in-out"></div>
                                                <div className="absolute top-1/2 -translate-y-1/2 start-0 end-0 text-center opacity-0 group-hover:opacity-100 duration-500">
                                                    <Link to="#" onClick={() => {
                                                        handleCLick(index);
                                                        return false;
                                                    }} className="inline-flex justify-center items-center size-9 bg-red-500 text-white rounded-full lightbox"><FiCamera className="size-4 align-middle"></FiCamera></Link>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            
                        </div>

                        <h5 className="text-2xl font-semibold mt-5">{tour?.name ? tour.name : ''}</h5>
                        <p className="flex items-center text-slate-400 font-medium mt-2">
                            <FiMapPin className="size-4 me-1"></FiMapPin>{tour.country?.name} / {tour.province?.name} </p>

                        <ul className="list-none">
                            <li className="inline-flex items-center me-5 mt-5">
                                <FiClock className="size-6 stroke-[1.5] text-red-500"></FiClock>

                                <div className="ms-3">
                                    <p className="font-medium">{i18n.t("duration")}</p>
                                    <span className="text-slate-400 font-medium text-sm">{tour.duration} {i18n.t("day")}</span>
                                </div>
                            </li>

                            <li className="inline-flex items-center me-5 mt-5">
                                <FiActivity className="size-6 stroke-[1.5] text-red-500"></FiActivity>
                                <div className="ms-3">
                                    <p className="font-medium">{i18n.t("type")}</p>
                                    <span className="text-slate-400 font-medium text-sm">{tour.category?.name}</span>
                                </div>
                            </li>

                            <li className="inline-flex items-center me-5 mt-5">
                                <FiDollarSign className="size-6 stroke-[1.5] text-red-500"></FiDollarSign>
                                <div className="ms-3">
                                    <p className="font-medium">{i18n.t("price")}</p>
                                    <span className="text-slate-400 font-medium text-sm">
                                        {
                                            tour.discount > 0 
                                            ? <><s>{tour.price_text}</s> {tour.discount_text}</> 
                                            : <>{tour.price_text}</>
                                        }
                                    </span>
                                </div>
                            </li>

                        </ul>

                        <div className="mt-6">
                            <h5 className="text-lg font-semibold">{i18n.t("descriptions")} :</h5>
                            <p className="text-slate-400 mt-6" dangerouslySetInnerHTML={{ __html: tour.descriptions }}></p>
                        </div>

                        <div className="mt-6">
                            <h5 className="text-lg font-semibold">{i18n.t("booking")}</h5>

                            <form className="mt-6" 
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    let options = [];
                                    options = tour?.options?.map((data) => {
                                        return {
                                            id: data.id,
                                            value: data.items.filter((item) => item.selected === true)[0]?.id
                                        }
                                    })
                                    form.content_id = tour?.id;
                                    form.options = options

                                    

                                    try {
                                        TourAPI.creatTransection(form)
                                        Swal.fire({
                                            text: i18n.t("success"),
                                            icon: 'success',
                                            confirmButtonText: i18n.t("ok"),
                                            confirmButtonColor: "green"
                                        }).then(() => {
                                            window.location.reload();
                                        })
                                    } catch (_) {
                                        Swal.fire({
                                            text: i18n.t("somethingWentWrong"),
                                            icon: 'error',
                                            confirmButtonText: i18n.t("ok"),
                                            confirmButtonColor: "green"
                                        })
                                    }
                            
                                }}>
                                <div className="grid lg:grid-cols-12 lg:gap-6">
                                    <div className="lg:col-span-12 mb-5">
                                        <div className="text-left">
                                            <label htmlFor="name" className="font-semibold">{i18n.t("name")} :</label>
                                            <input required onChange={changeValue} name="name" id="name" type="text" className="mt-3 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0" placeholder=""/>
                                        </div>
                                    </div>
    
                                    <div className="lg:col-span-6 mb-5">
                                        <div className="text-left">
                                            <label htmlFor="email" className="font-semibold">{i18n.t("email")} :</label>
                                            <input required onChange={changeValue} name="email" id="email" type="email" className="mt-3 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0" placeholder=""/>
                                        </div>
                                    </div>

                                    <div className="lg:col-span-6 mb-5">
                                        <div className="text-left">
                                            <label htmlFor="phone" className="font-semibold">{i18n.t("phone")} :</label>
                                            <input required onChange={changeValue} name="phone" id="phone" type="phone" className="mt-3 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0" placeholder=""/>
                                        </div>
                                    </div>

                                    {
                                        tour?.options?.map((data) => <div key={data.id} className="lg:col-span-12 mb-5">
                                            <label className="form-label font-medium text-slate-900 dark:text-white">{data.name} :</label>
                                            <div className="relative mt-2">
                                                
                                                <select required key={data.id}
                                                onChange={(e) => {
                                                    let newData = {
                                                        ...tour,
                                                        options: tour?.options?.map((option) => {
                                                            if (option.id === data.id) {
                                                                return {
                                                                    ...option, 
                                                                    items: option.items.map((item) => ({
                                                                        ...item, 
                                                                        selected: item.id == e.target.value, 
                                                                    })),
                                                                };
                                                            }
                                                            return option; 
                                                        }),
                                                    };
                                                    setTour(newData);
                                                }} 
                                                className="form-select w-full py-2 px-3 ps-10 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-md outline-none border border-gray-100 dark:border-gray-800 focus:ring-0">
                                                    {
                                                        data?.items?.map((item) =>  <option key={item.id} selected={item.selected} value={item.id}>{item.name}</option> )
                                                    }
                                                </select>
                                            </div>
                                        </div>)
                                    }

                                    

                                </div>

                                <div className="grid grid-cols-1">
                                    <div className="mb-5">
                                        <div className="text-left">
                                            <label htmlFor="description" className="font-semibold">{i18n.t("descriptions")} :</label>
                                            <textarea required onChange={changeValue} name="description" id="description" className="mt-3 w-full py-2 px-3 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0 h-28" placeholder=""></textarea>
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" id="submit" name="send" className="py-2 px-5 inline-block tracking-wide align-middle duration-500 text-base text-center bg-red-500 text-white rounded-md w-full">{i18n.t("booking")}</button>
                            </form>
                        </div>

                    </div>

                </div>
            </div>
        </section>
        <Footer/>
        <Switcher/>
        {isOpen && (
                <Lightbox
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => setOpen( false )}
                    onMovePrevRequest={() =>
                        setActiveIndex((photoIndex + images.length - 1) % images.length,
                        )
                    }
                    onMoveNextRequest={() =>
                        setActiveIndex((photoIndex + 1) % images.length,
                        )
                    }
                />
            )}
        </>
    )
}