import React from "react"
import {ContactAPI } from "../../Api/contact"
import Swal from 'sweetalert2';
import i18n from "../../i18n";

export default function ViewModel(){
    const [contact, setContact] = React.useState({});
    const [form, setForm] = React.useState({});

    React.useEffect(()=>{
        ContactAPI.fetchContact().then((resp)=>setContact(resp));
    },[])

    const changeValue = (e) => {
        setForm({
          ...form,
          [e.target.name]: e.target.value,
        })
    }

    const sentMessage = () => {
        try {
            ContactAPI.sentMessage(form).then((resp)=>console.log(resp))
            Swal.fire({
                text: i18n.t("success"),
                icon: 'success',
                confirmButtonText: i18n.t("ok"),
                confirmButtonColor: "green"
            }).then(() => {
                window.location.reload();
            })
        } catch (_) {
            Swal.fire({
                text: i18n.t("somethingWentWrong"),
                icon: 'error',
                confirmButtonText: i18n.t("ok"),
                confirmButtonColor: "green"
            })
        }
    }

    React.useEffect(()=>{
        console.log("form", form)
    },[form])
    
    return {
        contact,
        changeValue,
        sentMessage,
    }
}