import React from "react"
import { TourAPI } from "../../../../Api/tour";

export default function ViewModel() {
    const [tour, setTour] = React.useState([]);

    React.useEffect(()=>{
        TourAPI.fetchTour({page:1, limit:6, filter:""}).then((response)=> {
            setTour(response);
        })
    },[])

    return {
        tour
    }
}