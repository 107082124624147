import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

const Pagination = ({ page, url, params, totalPage }) => {
  const [currentPage, setCurrentPage] = useState(page);

  React.useEffect(()=>{
    setCurrentPage(page);
  },[page])

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const renderPageNumbers = () => {
    const pages = [];
    var param = "";
    if((params ?? []).length > 0){
      param = "&"+(params ?? []).join("&")
    }
    for (let i = 1; i <= totalPage; i++) {
      pages.push(
        <li key={i}>
          <Link
            to={url+"?page="+i+param}
            onClick={() => setCurrentPage(i)}
            className={`size-[40px] inline-flex justify-center items-center ${
              currentPage === i ? 'text-white bg-red-500 border-red-500' : 'text-slate-400 bg-white hover:text-white border-gray-100 dark:border-gray-800 hover:border-red-500 hover:bg-red-500'
            }`}
          >
            {i}
          </Link>
        </li>
      );
    }
    return pages;
  };

  return (
    <div className="grid md:grid-cols-12 grid-cols-1 mt-6">
      <div className="md:col-span-12 text-center">
        <nav aria-label="Page navigation example">
          <ul className="inline-flex items-center -space-x-px">
            {/* Previous Button */}
            <li>
              <Link
                to="#"
                onClick={handlePrevious}
                className={`size-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-s-3xl ${
                  currentPage === 1 ? 'cursor-not-allowed opacity-50' : 'hover:text-white border-gray-100 dark:border-gray-800 hover:border-red-500 hover:bg-red-500'
                }`}
              >
                <FiChevronLeft className="size-5 rtl:rotate-180 rtl:-mt-1" />
              </Link>
            </li>

            {/* Page Numbers */}
            {renderPageNumbers()}

            {/* Next Button */}
            <li>
              <Link
                onClick={handleNext}
                className={`size-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-e-3xl ${
                  currentPage === totalPage ? 'cursor-not-allowed opacity-50' : 'hover:text-white border-gray-100 dark:border-gray-800 hover:border-red-500 hover:bg-red-500'
                }`}
              >
                <FiChevronRight className="size-5 rtl:rotate-180 rtl:-mt-1" />
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Pagination;