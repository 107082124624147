import React from 'react';
import ModalVideo from 'react-modal-video';
import { Link } from "react-router-dom";
import '../../../../../node_modules/react-modal-video/scss/modal-video.scss'
import { DataAPI } from '../../../../Api/data';

export default function Banner(){
    let [loading, isLoading] =  React.useState(true)
    let [youtubeLink, setYoutubeLink] = React.useState("")
    let [bannerImage, setBannerImage] = React.useState("")
    let [text, setText] = React.useState("")

    React.useEffect(()=>{
        DataAPI.fetchBanner().then((resp) => {
            isLoading(false);
            console.log(resp);
            setBannerImage(resp.image.url);
            setText(resp.text);
            setYoutubeLink(resp.youtube_link);
            console.log(resp.youtube_link);
            isLoading(false);
        })
    },[])

    React.useEffect(()=>{
        console.log("youtubeLink", youtubeLink)
    },[youtubeLink])

    return (
        loading 
        ? <></> 
        : <section 
            className={ bannerImage === "" 
                ? "relative md:pt-72 md:pb-60 py-36 table w-full items-center bg-[url('../../assets/images/bg/1.jpg')] bg-top bg-no-repeat bg-cover "
                : "relative md:pt-72 md:pb-60 py-36 table w-full items-center bg-top bg-no-repeat bg-cover "
            }
            style={{ backgroundImage: `url(${bannerImage})` }}
        >
            <div className="absolute inset-0 bg-slate-900/40">
            <div>
                <iframe
                    className="w-full absolute h-full inset-0"
                    style={{ marginTop: '50px' }}
                    src={youtubeLink}
                    allow="autoplay; fullscreen"
                    allowFullScreen
                ></iframe>
            </div>
            </div>
            <div className="container relative">
                <div className="grid md:grid-cols-12 grid-cols-1 items-center mt-10 gap-[30px]">
                    <div className="lg:col-span-8 md:col-span-7 md:order-1 order-2">
                        <p className="text-white/70 text-xl max-w-xl" dangerouslySetInnerHTML={{ __html: text }}></p>
                    </div>
                </div>
            </div>
        </section>
    )
}