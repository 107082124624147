import Network from "../helpers/network";

export const DataAPI = {
    fetchBanner: async () => {
        const response = await Network.get(`/v1/public/banner`);
        return response.data;
    },
    fetchCountryMenu: async () => {
        const response = await Network.get(`/v1/public/country/menu`);
        return response.data;
    },
    fetchCountryByID: async (id) => {
        const response = await Network.get(`/v1/public/country/${id}`);
        return response.data;
    },
    fetchProvinceByID: async (id) => {
        const response = await Network.get(`/v1/public/province/${id}`);
        return response.data;
    },
    fetchDestination: async (id) => {
        const response = await Network.get(`/v1/public/destination`);
        return response.data;
    },
}