import Network from "../helpers/network";

export const BlogAPI = {
    fetchBlogPanigtion: async ({page, limit, filter}) => {
        const response = await Network.get(`/v1/public/blog?page=${page}&page_size=${limit}${filter}`);
        return response;
    },
    fetchBlog: async ({page, limit, filter}) => {
        const response = await Network.get(`/v1/public/blog?page=${page}&page_size=${limit}${filter}`);
        return response.data;
    },
    fetchBlogByID: async ({id}) => {
        const response = await Network.get(`/v1/public/blog/${id}`);
        return response.data;
    },
}