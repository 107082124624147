import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate  } from 'react-router-dom';
import i18n from "../i18n";
import { TourAPI } from "../Api/tour";
import { CarRentalAPI } from "../Api/carrental";

export default function FilterCarRental(){
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const page = queryParams.get('page') || 1;
    const countryID = queryParams.get('country_id') || 0;
    const provinceID = queryParams.get('province_id') || 0;
    const min = queryParams.get('min') || 0;
    const max = queryParams.get('max') || 0;
    const categoryParam = queryParams.get('category') || "";

    let [minValue, setMinValue] = useState(0)
    let [maxValue, setMaxValue] = useState(200)
    let [maxPriceFilter, setMaxPriceFilter] = useState(500)
    let [prefix, setPrefix] = useState("$")
    let [category, setCategory] = useState([])

    const language = useSelector((state) => state.language.lang)

    React.useEffect(()=>{
        CarRentalAPI.fetchCategory().then((resp) => {
            let cateSelected = categoryParam.split(",");
            var newCategory = resp.map((data) => {
                data.checked = (cateSelected.indexOf(data.id.toString()) > -1);
                return data;
            });
            setCategory(newCategory);
        });
    },[])

    if(categoryParam !== ""){
        
    }

    const changeMinValue = (e) =>{
        setMinValue(e.target.value);
    }

    const changeMaxValue = (e) =>{
        setMaxValue(e.target.value);
    }

    const searchData = () => {
        let params = [];
        params.push("min="+minValue)
        params.push("max="+maxValue)

        if(page > 0){
            params.push("page="+page);
        }
        if(countryID > 0){
            params.push("country_id="+countryID);
        }
        if(provinceID > 0){
            params.push("province_id="+provinceID);
        }

        let categoryData = []
        for(var index in category){
            var item = category[index];
            if(item.checked){
                categoryData.push(item.id);
            }
        }
        if(categoryData.length > 0){
            params.push("category="+categoryData.join(","));
        }
        navigate("/car-rental?"+params.join("&")); 
        window.location.reload();
    }

    React.useEffect(()=>{
        switch (language) {
            case 'th':
                setMaxPriceFilter(50000);
                setPrefix("฿")
                break;
        
            default:
                setMaxPriceFilter(500);
                break;
        }
        
        if(min > 0){
            setMinValue(parseInt(min))
        }
        if(max > 0){
            setMaxValue(parseInt(max))
        }else{
            switch (language) {
                case 'th':
                    setMaxValue(50000);
                    break;
            
                default:
                    setMaxValue(200);
                    break;
            }
        }
    },[])

    return(
        <div className="lg:col-span-4 md:col-span-5">
            <div className="p-4 rounded-md shadow dark:shadow-gray-700 sticky top-20">
                <div>
                    <h5 className="text-lg font-medium">{i18n.t("price")}</h5>

                    <div className="range-slider mt-3">
                        <span className="flex justify-between pb-2">
                            <span>
                                <label htmlFor="" className="text-lg">{prefix}</label>
                                <input type="number" className="text-slate-400" value={minValue} min={0} max={maxPriceFilter} onChange={(e)=>changeMinValue(e)}/>
                            </span>

                            <span>
                                <label htmlFor="" className="text-lg">{prefix}</label>
                                <input type="number" className="text-slate-400" value={maxValue} min={0} max={maxPriceFilter} onChange={(e)=>changeMaxValue(e)}/>
                            </span>
                        </span>
                        <input value={minValue} min={0} max={maxPriceFilter} step="5" type="range" onChange={(e)=>changeMinValue(e)}/>
                        <input value={maxValue} min={0} max={maxPriceFilter} step="5" type="range" onChange={(e)=>changeMaxValue(e)}/>
                    </div>
                </div>

                <div className="mt-6">
                    <h5 className="text-lg font-medium">{i18n.t("category")}</h5>

                    <div className="mt-3">
                        {
                            category.map((item, index) =>  <div key={index} className="flex items-center mb-0">
                                <input className="form-checkbox rounded border-gray-100 dark:border-gray-800 text-red-500 focus:border-red-300 focus:ring focus:ring-offset-0 focus:ring-red-500/20 focus:ring-opacity-50 me-2" 
                                onChange={() => {
                                    const newCategories = [...category];
                                    newCategories[index].checked = !newCategories[index].checked;
                                    setCategory(newCategories);
                                }}
                                type="checkbox" checked={item.checked} id="Bali"/>
                                <label className="form-checkbox-label text-slate-400" htmlFor="Bali">{item.name}</label>
                            </div>)
                        }
                       
                    </div>
                </div>

                <div className="mt-6">
                <button onClick={() => searchData()} type="button" id="search" name="send" className="py-2 px-5 inline-block tracking-wide align-middle duration-500 text-base text-center bg-red-500 text-white rounded-md mt-2">{i18n.t("search")}</button>
                </div>
            </div>
        </div>
    )
}