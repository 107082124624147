import React from "react";
import { Link, useParams } from "react-router-dom";

import Navbar from "../../components/navbar";
import Blogs from '../../components/blogs'
import BlogSidebar from "../../components/blog-sidebar";
import Footer from "../../components/footer";
import Switcher from "../../components/switcher";

import blogImg from "../../assets/images/blog/9.jpg"
import { BlogAPI } from "../../Api/blog";
import i18n from "../../i18n";

export default function BlogDetail(){
    let params = useParams();
    let id = params.id
    const [blog, setBlog] = React.useState({});
    
    React.useEffect(()=>{
        BlogAPI.fetchBlogByID({id:id}).then((resp)=>{
            setBlog(resp);
        })
    },[id])


    return(
        <>
        <Navbar navclass="defaultscroll is-sticky" navlight={true} manuclass="justify-end nav-light"/>
        <section className="relative table w-full items-center py-36 bg-[url('../../assets/images/bg/cta.jpg')] bg-top bg-no-repeat bg-cover">
            <div className="absolute inset-0 bg-gradient-to-b from-slate-900/60 via-slate-900/80 to-slate-900"></div>
            <div className="container relative">
                <div className="grid grid-cols-1 pb-8 text-center mt-10">
                    <h3 className="text-4xl leading-normal tracking-wider font-semibold text-white">{blog?.title ? blog.title : ''}</h3>

                    <ul className="list-none mt-6">
                        <li className="inline-block text-white/50 mx-5"> <span className="text-white block">{i18n.t("author")} :</span> <span className="block">{blog?.created_by?.first_name+" "+blog?.created_by?.last_name}</span></li>
                    </ul>
                </div>
            </div>
        </section>

        <section className="relative md:py-24 py-16">
            <div className="container">
                <div className="grid md:grid-cols-12 grid-cols-1 gap-6">
                    <div className="lg:col-span-8 md:col-span-6">
                        <div className="relative overflow-hidden rounded-md shadow dark:shadow-gray-800">

                            <img src={blog?.image ? blog.image.url : blogImg} alt=""/>

                            <div className="p-6">
                                <p className="text-slate-400" dangerouslySetInnerHTML={{ __html: blog.content }}></p>
                            </div>
                        </div>
                    </div>

                    <BlogSidebar
                        name={blog?.created_by?.first_name+" "+blog?.created_by?.last_name}
                        image={blog?.created_by?.image?.url}
                        />
                </div>
            </div>

            <Blogs/>
        </section>
        <Footer/>
        <Switcher/>
        </>
    )
}