import React from 'react';

import Tagline from "../../components/tagline";
import Navbar from "../../components/navbar";
import Form from '../../components/form';
import TopDestinationOne from '../../components/top-destination-one';
import About from '../../components/about';
import Client from '../../components/client';
import Blogs from '../../components/blogs';
import Footer from '../../components/footer';
import Switcher from '../../components/switcher';

import Banner from './components/banners/banner';
import Tour from './components/tours/tour';
import CarRental from './components/carrental/carrental';
import Ticket from './components/ticket/ticket';

export default function Index(){
    

    return(
        <>
        <Tagline/>
        <Navbar navclass="defaultscroll is-sticky tagline-height nav-sticky" navlight={true} manuclass="justify-end nav-light"/>
        <Banner />

        <div className="container relative -mt-16 z-1">
            <Form/>
        </div>

        <section className="relative md:py-24 py-16 overflow-hidden">
            <TopDestinationOne/>
            <Tour />
            <Ticket />
            <CarRental />
            <About/>
            <Client/>
            <Blogs/>
        </section>
        <Footer/>
        <Switcher/>
        </>
    )
}