
let baseAPI = "https://api.happytripchiangmai.com";
const Network = {
    get: async (url)=> {
        let language = localStorage.getItem("language")
        return await fetch(baseAPI+url,
            {
                method: 'GET',
                headers: { 
                    'Content-Type': 'application/json',
                    'Accept-Language': language,
                }
            }
        ).then(response => response.json())
    },
    post: async (url, body)=> {
        return await fetch(baseAPI+url,
            {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            }
        ).then(response => response.json())
    },
}

export default Network;