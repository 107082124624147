import React from "react";
import { ContactAPI } from "../../Api/contact";

export default function ViewModel() {
    const [teams, setTeams] = React.useState([]);
    const [aboutUs, setAboutUs] = React.useState({});

    React.useEffect(()=>{
        ContactAPI.fetchTeam().then((resp) => {
            setTeams(resp);
        })
    },[])

    React.useEffect(()=>{
        ContactAPI.fetchAbouUS().then((resp) => {
            setAboutUs(resp);
        })
    },[])

    return {
        teams,
        aboutUs,
    }
}