import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';

import en from './locales/en.json';
import th from './locales/th.json';

i18n
  .use(HttpBackend)
  .use(LanguageDetector) 
  .use(initReactI18next) 
  .init({
    fallbackLng: 'en',
    resources: {
      en: {
        translation: en
      },
      th: {
        translation: th
      }
    },
    interpolation: {
      escapeValue: false, 
    },
  });

export default i18n;
