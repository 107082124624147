import React from "react"
import { TicketAPI } from "../../../../Api/ticket";

export default function ViewModel() {
    const [ticket, setTicket] = React.useState([]);

    React.useEffect(()=>{
        TicketAPI.fetchTicket({page:1, limit:6, filter:""}).then((response)=> {
            setTicket(response);
        })
    },[])

    return {
        ticket
    }
}