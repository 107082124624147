import React from "react";
import { Link } from "react-router-dom";

import Navbar from "../../components/navbar";
import Filter from "../../components/filter";
import Footer from "../../components/footer";
import Switcher from "../../components/switcher";

import {FiMapPin } from '../../assets/icons/vander'
import { TourAPI } from "../../Api/tour";

import i18n from "../../i18n";
import Pagination from "../../components/pagination";
import { useLocation } from 'react-router-dom';
import { DataAPI } from "../../Api/data";

export default function Tour(){
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const page = queryParams.get('page') || 1;
    const countryID = queryParams.get('country_id') || 0;
    const provinceID = queryParams.get('province_id') || 0;
    const min = queryParams.get('min') || 0;
    const max = queryParams.get('max') || 0;
    const rating = queryParams.get('rating') || "";
    const category = queryParams.get('category') || "";

    const [tours, setTours] = React.useState([]);
    const [pagination, setPagination] = React.useState({});
    const [country, setCountry] = React.useState("");
    const [province, setProvince] = React.useState("");

    React.useEffect(()=>{
        getData(page);
    },[page, countryID, provinceID])

    function getData(page) {

        let params = [];
        if (countryID > 0) {
            params.push("country_id=" + countryID);
        }
        if (provinceID > 0) {
            params.push("province_id=" + provinceID);
        }
        if (min > 0) {
            params.push("min=" + min);
        }
        if (max > 0) {
            params.push("max=" + max);
        }
        if (rating !== "") {
            params.push("rating=" + rating);
        }
        if (category !== "") {
            params.push("category=" + category);
        }

        var filter = "";
        if (params.length > 0) {
            filter += "&" + params.join("&");
        }

        TourAPI.fetchTourPanigtion({ page: page, limit: 10, filter: filter }).then((response) => {
            setTours(response.data);
            let pag = response.pagination;
            setPagination({
                url: "/tour",
                params: params,
                page: pag.page,
                pageSize: pag.page_size,
                total: pag.total,
                totalPage: pag.total_page,
            });
        });
    }

    React.useEffect(()=>{
        if(countryID > 0){
            try {
                    DataAPI.fetchCountryByID(countryID).then((resp)=>{
                        if(resp !== undefined){
                            setCountry(resp.name);
                        }
                })
            } catch (_) {}
        }
    },[countryID])

    React.useEffect(()=>{
        if(provinceID > 0){
            try {
                DataAPI.fetchProvinceByID(provinceID).then((resp)=>{
                    if(resp !== undefined){
                       setProvince(resp.name);
                    }
               })
           } catch (_) {}
        }
     },[provinceID])

    return(
        <>
        <Navbar navclass="defaultscroll is-sticky" navlight={true} manuclass="justify-end nav-light"/>

        <section className="relative table w-full items-center py-36 bg-[url('../../assets/images/bg/cta.jpg')] bg-top bg-no-repeat bg-cover">
            <div className="absolute inset-0 bg-gradient-to-b from-slate-900/60 via-slate-900/80 to-slate-900"></div>
            <div className="container relative">
                <div className="grid grid-cols-1 pb-8 text-center mt-10">
                    <h3 className="text-4xl leading-normal tracking-wider font-semibold text-white">{i18n.t("tours_packages")}</h3>
                </div>
            </div>

            {
                country === "" 
                ? <></>
                : <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="tracking-[0.5px] mb-0 inline-block">
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">{country}</Link></li>
                        {
                            province === ""
                            ? <></>
                            : <>
                             <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><i className="mdi mdi-chevron-right"></i></li>
                             <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white" aria-current="page">{province}</li>
                            </>
                        }
                    </ul>
                </div>
            }
            
        </section>

        <section className="relative md:py-24 py-16">
            <div className="container relative">
                <div className="grid md:grid-cols-12 grid-cols-1 gap-6">
                    
                    <div className="lg:col-span-8 md:col-span-7">
                        <div className="grid lg:grid-cols-2 grid-cols-1 gap-6">
                        {tours.map((item,index)=>{
                                return(
                                    <div className="group rounded-md shadow dark:shadow-gray-700" key={index}>
                                        <div className="relative overflow-hidden rounded-t-md shadow dark:shadow-gray-700 mx-3 mt-3">
                                            <a href={"/tour-detail/"+item.id}><img src={item.images?.[0]?.url} className="scale-125 group-hover:scale-100 duration-500" alt=""/>
                                            {item.tagText && (
                                                <div className="absolute top-0 start-0 p-4">
                                                    <span className="bg-red-500 text-white text-[12px] px-2.5 py-1 font-medium rounded-md h-5">{item.name}</span>
                                                </div>
                                            )}

                                            {
                                                item.discount > 0 
                                                ? <div className="absolute top-0 end-0 p-4">
                                                    <Link to="#" className="size-8 inline-flex justify-center items-center bg-red-500 dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-full text-slate-100 dark:text-slate-700 focus:text-red-500 dark:focus:text-red-500 hover:text-red-500 dark:hover:text-red-500"><i className="mdi mdi-sale text-[20px] align-middle"></i></Link>
                                                </div>
                                                : <></>
                                            }
                                            </a>
                                        </div>

                                        <div className="p-4">
                                            <p className="flex items-center text-slate-400 font-medium mb-2"><FiMapPin className="text-red-500 size-4 me-1">
                                                </FiMapPin> {item.province?.name} / {item.province.name}
                                            </p>
                                            <Link to={`/tour-detail/${item.id}`} className="text-lg font-medium hover:text-red-500 duration-500 ease-in-out">{item.name}</Link>

                                            <div className="flex items-center mt-2">
                                                <span className="text-slate-400">Rating:</span>
                                                <ul className="text-lg font-medium text-amber-400 list-none ms-2 space-x-1">
                                                    {[...Array(5)].map((_, index) => (
                                                        <li className="inline" key={index}>
                                                            <i className={`mdi ${item.rating >= index + 1 ? 'mdi-star' : 'mdi-star-outline'} align-middle`}></i>
                                                        </li>
                                                    ))}
                                                    <li className="inline text-black dark:text-white text-sm">5.0({item.rating})</li>
                                                </ul>
                                            </div>
                                            
                                            <div className="mt-4 pt-4 flex justify-between items-center border-t border-slate-100 dark:border-gray-800">
                                                <h5 className="text-lg font-medium text-red-500">
                                                    {
                                                        item.discount > 0 
                                                        ? <><s className="text-gray-500">{item.price_text}</s> {item.discount_text}</> 
                                                        : <>{item.price_text}</>
                                                    }
                                                </h5>
                                            </div>
                                            <div className="mt-4 pt-4 flex justify-between items-center border-t border-slate-100 dark:border-gray-800">
                                                <Link to={"/tour-detail/"+item.id} className="text-slate-400 hover:text-red-500">{i18n.t("explore_now")}<i className="mdi mdi-arrow-right"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                        <Pagination 
                            {...pagination}
                        />
                    </div>

                    <Filter/>

                </div>
            </div>
        </section>
        <Footer/>
        <Switcher/>
        </>
    )
}